import React, { useState } from 'react';

import './Contact.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useSendRoseEmail from '../../hooks/useSendEmail';
import { Loader } from '../../components/loader/Loader';

export const Contact: React.FC = (): JSX.Element => {

    const { sendEmail, loading, error, success } = useSendRoseEmail();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const [validationErrors, setValidationErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };

        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required.';
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required.';
        }

        if (!formData.email.trim()) {
            errors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            errors.email = 'Invalid email address.';
        }

        if (!formData.message.trim()) {
            errors.message = 'Message is required.';
        } else if (formData.message.trim().length < 10) {
            errors.message = 'Message should be at least 10 characters long.';
        }

        setValidationErrors(errors);
        return Object.values(errors).every(err => !err);
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            
            const emailSent = await sendEmail({
                type: 'contact', 
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                message: formData.message
            });

            if (emailSent) {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    message: ''
                });
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Contact Us | Rose Club</title>
                <meta name="description" content="Contact Rose Club for inquiries, reservations, and feedback." />
                <meta name="keywords" content="contact, rose, club, nightclub, stockholm, sweden, reservations, feedback" />
                <meta property="og:title" content="Contact Us | Rose Club" />
                <meta property="og:description" content="Contact Rose Club for inquiries, reservations, and feedback." />
                <meta property="og:url" content="https://roseclub.se/contact" />
                <meta name="canonical" content="https://roseclub.se/contact" />
                <meta property="twitter:url" content="https://roseclub.se/contact" />
                <meta property="twitter:title" content="Contact Us | Rose Club" />
                <meta property="twitter:description" content="Contact Rose Club for inquiries, reservations, and feedback." />
            </Helmet>
            <section className="contact">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <h1 className="title mt-0">Contact us</h1>
                            <h5>Larger groups & events</h5>
                            <a href="mailto:event@roseclub.se">event@roseclub.se</a>
                            <h5>Lost & found</h5>
                            <a href="mailto:lostandfound@roseclub.se">lostandfound@roseclub.se</a>
                            <h5>VIP Table</h5>
                            <a href="mailto:reservations@roseclub.se">reservations@roseclub.se</a>
                            <h5>Nightclub manager</h5>
                            <a href="mailto:texas@berzeliigroup.com">texas@berzeliigroup.com</a>
                            <h5>Visit</h5>
                            <p className="capitalize address">
                                Hamngatan 4<br />
                                111 47 Stockholm<br />
                            </p>
                            <h5>Socials</h5>
                            <a href="https://www.instagram.com/roseclubsthlm" target="_BLANK" rel="noreferrer">
                                <img src="/img/icons/insta.png" alt="Instagram icon" height="20" />
                            </a>
                        </div>
                        <div className="col-lg-6">
                        <p className="mb-0 mt-2 form-title">For all other inquiries, simply use the form below:</p>
                        <form onSubmit={handleFormSubmit} className="contact-form">
                            <div className="dual-wrapper">
                                <div className="first-name-wrapper w-100">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" 
                                        id="firstName"
                                        name="firstName" 
                                        placeholder="First Name" 
                                        value={formData.firstName} 
                                        onChange={handleInputChange}
                                        className={`form-control w-100 box-3d ${validationErrors.firstName ? 'is-invalid' : ''}`} />
                                    {validationErrors.firstName && <div className="invalid-feedback">{validationErrors.firstName}</div>}
                                </div>

                                <div className="last-name-wrapper w-100">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" 
                                        id="lastName"
                                        name="lastName" 
                                        placeholder="Last Name" 
                                        value={formData.lastName} 
                                        onChange={handleInputChange}
                                        className={`form-control w-100 box-3d ${validationErrors.lastName ? 'is-invalid' : ''}`} />
                                    {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" 
                                    id="email"
                                    name="email" 
                                    placeholder="Email" 
                                    value={formData.email} 
                                    onChange={handleInputChange}
                                    className={`form-control box-3d ${validationErrors.email ? 'is-invalid' : ''}`} />
                                {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea id="message"
                                    name="message"
                                    placeholder="Your Message"
                                    value={formData.message}
                                    rows={4}
                                    onChange={handleInputChange}
                                    className={`form-control box-3d ${validationErrors.message ? 'is-invalid' : ''}`}></textarea>
                                {validationErrors.message && <div className="invalid-feedback">{validationErrors.message}</div>}
                            </div>

                            <button type="submit" className="btn btn-default mt-3">Send Message</button>
                            {loading && <Loader />}
                            {success && <div className="alert alert-success mt-3">{success}</div>}
                            {error && <div className="alert alert-danger mt-3">{error}</div>}
                        </form>
                        </div>
                    </div>

                    {/* FAQ */}
                    <div className="row mt-5 pt-5 faq mb-5 pt-5">
                        <h2 className="faq-title">Frequently Asked Questions</h2>
                        <div className="accordion accordion-flush" id="faq">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What are the opening hours?
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq">
                            <div className="accordion-body">
                                Normal open hours are Wednesday 22:00 - 03:00, Friday 23:00 - 05:00, and Saturday 23:00 - 05:00. 
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Do you have a guest list or VIP reservations?
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq">
                            <div className="accordion-body">
                                Yes! You can find out more about VIP reservations <Link to="/vip">here</Link> and guest list <Link to="/guestlist">here</Link>.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Do you have a lost and found?
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    Yes! If you have lost something at Rose Club, please contact us at <a href="mailto:lostandfound@roseclub.se">lostandfound@roseclub.se</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    );
}