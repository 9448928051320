import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/home/Home';
import { Lilys } from '../pages/lilys/Lilys';
import { Navbar } from '../components/navbar/Navbar';
import { Footer } from '../components/footer/Footer';
import { Guestlist } from '../pages/guestlist/Guestlist';
import ScrollToTop from './scroll';
import { Vip } from '../pages/vip/Vip';
import { Contact } from '../pages/contact/Contact';
import NotFound from '../pages/notfound/NotFound';
// import { Popup } from '../components/popup/Popup';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <>
      <ScrollToTop />
      {location.pathname !== '/' && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lilys" element={<Lilys />} />
        <Route path="/guestlist" element={<Guestlist />} />
        <Route path="/vip" element={<Vip />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      {/* <Popup /> */}
      {location.pathname !== '/' && <Footer />}
    </>
  );
};

export default AppRoutes;
